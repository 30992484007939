<template>
    <div>
        <header v-if="brand_slug != 'turbotlumaczenia_pl'">
            <div class="container" v-bind:class="this.$route.name">
                <nav aria-labelledby="" aria-label="" class="navbar navbar-expand-md navbar-light">
                    <a href="/" class="navbar-brand vertical-center" :title="brand_slug">
                        <img :src="require(`../../img/${brand_slug}/logo.svg`)" class="logo-desktop" />
                    </a>
                    <b-collapse id="nav-collapse" is-nav>
                        <ul class="navbar-nav ml-md-auto" v-if="loggedIn===true">
                            <li class="nav-item">
                                <router-link :to="{name:'settings', params: { locale: locale }}" class="nav-link" :title="$t('My account')">
                                    {{ $t('My account') }}
                                    <div class="bottom-bar"></div>
                                </router-link>
                            </li>
                            <li class="nav-item ">
                                <router-link :to="{name:'order-list', params: { locale: locale }}" class="nav-link" :title="$t('Orders history')">
                                    {{ $t('Orders history') }}
                                    <div class="bottom-bar"></div>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{name:'invoice-list', params: { locale: locale }}" class="nav-link" :title="$t('Invoices')">
                                    {{ $t('Invoices') }}
                                    <div class="bottom-bar"></div>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{name:'write', params: { locale: locale }}" class="nav-link btn-box grey" :title="$t('Quick qoute')">
                                    {{ $t('Quick qoute') }}
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{name:'top-up', params: { locale: locale }}" class="nav-link btn-box" :title="$t('Account settings')">
                                    {{ $t('Top up your account') }}
                                    <div class="bottom-bar"></div>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link logout" :title="$t('Log out')" @click.prevent="logout">
                                    {{ $t('Log out') }}
                                    <div class="bottom-bar"></div>
                                </a>
                            </li>
                            <Locale></Locale>
                        </ul>
                        <ul class="navbar-nav ml-md-auto" v-else>
                            <li>
                                <router-link :to="{name:'login', params:{ locale: locale }}" class="nav-link" :title="$t('Log in')">
                                    {{ $t('Log in') }}
                                    <div class="bottom-bar"></div>
                                </router-link>
                            </li>
                            <Locale></Locale>
                        </ul>
                    </b-collapse>
                </nav>
            </div>
        </header>
        <div class="header-menu-icon" @click="menu_icon_clicked">
            <span></span>
        </div>
        <div id="menu-rwdbox">
            <div class="contentbox">
                <div class="topbox">
                    <router-link :to="{name:'home', params: { locale: locale }}" :title="brand_slug">
                        <img :src="require(`../../img/${brand_slug}/logo-white.svg`)" class="logo-desktop" />
                    </router-link>
                </div>
                <div class="content">
                    <ul v-if="loggedIn===true">
                        <li class="nav-item">
                            <router-link :to="{name:'settings', params: { locale: locale }}" @click.native="menu_icon_clicked" class="nav-link" :title="$t('My account')">
                                {{ $t('My account') }}
                                <div class="bottom-bar"></div>
                            </router-link>
                        </li>
                        <li class="nav-item ">
                            <router-link :to="{name:'order-list', params: { locale: locale }}" @click.native="menu_icon_clicked" class="nav-link" :title="$t('Orders history')">
                                {{ $t('Orders history') }}
                                <div class="bottom-bar"></div>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name:'invoice-list', params: { locale: locale }}" @click.native="menu_icon_clicked" class="nav-link" :title="$t('Invoices')">
                                {{ $t('Invoices') }}
                                <div class="bottom-bar"></div>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name:'write', params: { locale: locale }}" @click.native="menu_icon_clicked" class="nav-link" :title="$t('Quick qoute')">
                                {{ $t('Quick qoute') }}
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name:'top-up', params: { locale: locale }}" @click.native="menu_icon_clicked" class="nav-link" :title="$t('Account settings')">
                                {{ $t('Top up your account') }}
                                <div class="bottom-bar"></div>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link logout" :title="$t('Log out')" @click.prevent="logout_mob">
                                {{ $t('Log out') }}
                                <div class="bottom-bar"></div>
                            </a>
                        </li>
                        <Locale></Locale>
                    </ul>
                    <ul v-else>
                        <li>
                            <router-link :to="{name:'login', params:{ locale: locale }}" class="nav-link" :title="$t('Log in')"  @click.native="menu_icon_clicked">
                                {{ $t('Log in') }}
                                <div class="bottom-bar"></div>
                            </router-link>
                        </li>
                        <Locale></Locale>
                    </ul>
                </div>
            </div>
        </div>
        <div id="paypobox-sidebar" v-if="brand_slug == 'dogadamycie_pl'">
            <div class="logo"></div>
            <div class="textbox">
                <div>{{ $t('Order today') }}</div>
                <div>{{ $t('Pay for 30 days') }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import Locale from "./Locale";
import {
    BDropdownItem, BNavItemDropdown, BIconArrowClockwise,
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
} from "bootstrap-vue";
import Helpers from "../helpers/Helpers";
import i18n from "../../../common/js/i18n";
import BrandConfig from "../BrandConfig";
import $ from "jquery";
export default {
    name: 'Nav',
    components: {
        Locale, BDropdownItem, BNavItemDropdown, BIconArrowClockwise,
        BNavbar,
        BNavbarBrand,
        BNavbarToggle,
        BCollapse,
    },
    directives: {
        'b-navbar': BNavbar,
        'b-navbar-brand': BNavbarBrand,
        'b-navbar-toggle': BNavbarToggle,
        'b-collapse': BCollapse,
        'b-dropdown-item': BDropdownItem,
        'b-nav-item-dropdown': BNavItemDropdown,
        'b-icon-arrow-clockwise': BIconArrowClockwise
    },
    data() {
        return {
            locale: i18n.locale,
            currency_amount_decimal: "",
            display_currency_code: '',
            brand_slug: BrandConfig.brandName
        }
    },
    created() {

        if(this.loggedIn){
            this.$root.$on("refresh-customer", () => {
                this.currency_amount_decimal = "";
                this.$store.dispatch("authorized/customer").then((r) => {
                    this.data();
                })
            });
        }

        this.$root.$on("customer-loaded", (() => {
            this.data();
        }));
    },
    mounted() {
        if (this.loggedIn) {
            this.data();
        }
        this.$root.$on("locale-changed",(locale)=>{
            this.locale = locale
        })
    },
    computed: {

        loggedIn: {
            get() {
                return this.$store.getters["common/isAuthorized"]
            },
            set(value) {
                this.$store.commit('updateIsAuthenticated', value)
            }
        },
    },
    methods: {
        logout() {
            Helpers.flushAuth(this.$store).then(() => {
                this.$router.push({name: "home"});
            });
        },
        logout_mob() {
            Helpers.flushAuth(this.$store).then(() => {
                this.$router.push({name: "home"});
            });

            this.menu_icon_clicked();
        },
        data() {
            const customer = this.$store.getters["authorized/customer"];
            if (customer) {
                const accountBalance = customer.data.account_balance;
                this.currency_amount_decimal = accountBalance.currency_amount_decimal;
                this.display_currency_code = accountBalance.currency_code;
            }
        },
        menu_icon_clicked() {
            if(!$('#menu-rwdbox').hasClass('active')) {
                $('#menu-rwdbox').addClass('active');
                setTimeout(function() {
                    $('#menu-rwdbox').addClass('anim');
                },1000);
            } else {
                $('#menu-rwdbox').removeClass('active');
                setTimeout(function(){
                    $('#menu-rwdbox').removeClass('anim');
                },1000);
            }
            $('.header-menu-icon').toggleClass('open')
        }
    }
}
</script>

