<template>
    <div>
        <footer>
            <div class="bg">
                <svg xmlns="http://www.w3.org/2000/svg" width="1620.001" height="540" viewBox="0 0 1620.001 540">
                    <path id="Path_28759" data-name="Path 28759" d="M1078,560.788l-268-269-268,269H0l540-540h540l540,540Z" transform="translate(0 -20.788)" fill="#13213c" opacity="0.411" style="mix-blend-mode: multiply;isolation: isolate"/>
                </svg>
            </div>
            <div class="container contentbox" v-if="brand_slug == 'dogadamycie_pl'">
                <router-link :to="{name:'home', params: { locale: locale }}" class="footer-logo" :title="brand_slug">
                    <img :src="require(`../../img/${brand_slug}/logo-white.svg`)" />
                </router-link>
                <div class="items">
                    <ul>
                        <li>
                            <h3>{{ $t('Certificates') }}</h3>
                            <div class="txtbox">
                                <div class="txt">
                                    {{ $t('LAUR MADE IN KOSZALIN 2017 Deloitte Technology Fast 50 Central Europe 2017 Forbes Diamond 2023 Business Pearl 2023 Quality Guarantee') }}
                                </div>
                                <a :href="brand_metas.quality_guarantee_url" v-if="brand_metas.quality_guarantee_url" class="subtitle" target="_blank">{{ $t('Quality guarantee') }}</a>
                            </div>
                        </li>
                        <li>
                            <h3>{{ $t('Information') }}</h3>
                            <div class="txtbox">
                                <ul>
                                    <li v-if="brand_metas.faq_url">
                                        <a :href="brand_metas.faq_url" target="_blank">{{ $t('FAQ') }}</a>
                                    </li>
                                    <li v-if="brand_metas.complaints_url">
                                        <a :href="brand_metas.complaints_url" target="_blank">{{ $t('Complaints') }}</a>
                                    </li>
                                    <li v-if="brand_metas.terms_of_service_url">
                                        <a :href="brand_metas.terms_of_service_url" target="_blank">{{ $t('Terms and Conditions') }}</a>
                                    </li>
                                    <li v-if="brand_metas.privacy_policy_url">
                                        <a :href="brand_metas.privacy_policy_url" target="_blank">{{ $t('Privacy Policy') }}</a>
                                    </li>
                                    <li v-if="brand_metas.blog_url">
                                        <a :href="brand_metas.blog_url" target="_blank">{{ $t('BLOG') }}</a>
                                    </li>
                                    <li v-if="brand_metas.free_consultation_url">
                                        <a :href="brand_metas.free_consultation_url" target="_blank">{{ $t('Free consultation') }}</a>
                                    </li>
                                    <li v-if="brand_metas.csr_url">
                                        <a :href="brand_metas.csr_url" target="_blank">{{ $t('CSR') }}</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <h3>{{ $t('Company') }}</h3>
                            <div class="txtbox">
                                <ul>
                                    <li v-if="brand_metas.about_us_url">
                                        <a :href="brand_metas.about_us_url" target="_blank">{{ $t('About us') }}</a>
                                    </li>
                                    <li v-if="brand_metas.industries_url">
                                        <a :href="brand_metas.industries_url" target="_blank">{{ $t('Industries') }}</a>
                                    </li>
                                    <li v-if="brand_metas.services_url">
                                        <a :href="brand_metas.services_url" target="_blank">{{ $t('Services') }}</a>
                                    </li>
                                    <li v-if="brand_metas.languages_url">
                                        <a :href="brand_metas.languages_url" target="_blank">{{ $t('Languages') }}</a>
                                    </li>
                                    <li v-if="brand_metas.case_studies_url">
                                        <a :href="brand_metas.case_studies_url" target="_blank">{{ $t('Case Studies') }}</a>
                                    </li>
                                    <li v-if="brand_metas.contact_url">
                                        <a :href="brand_metas.contact_url" target="_blank">{{ $t('Contact') }}</a>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'login', params:{ locale: locale }}" class="login">
                                            {{ $t('Log in') }}
                                        </router-link>
                                    </li>
                                </ul>
                                <div class="smbox">
                                    <a :href="brand_metas.facebook_url" class="sm-fb" v-if="brand_metas.facebook_url" target="_blank">
                                        <img :src="require(`../../img/icon-fb.svg`)" />
                                    </a>
                                    <a :href="brand_metas.linkedin_url" class="sm-in" v-if="brand_metas.linkedin_url" target="_blank">
                                        <img :src="require(`../../img/icon-linkedin.svg`)" />
                                    </a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <h3>{{ $t('Data') }}</h3>
                            <div class="txtbox">
                                <div class="txt">
                                    dogadamycie.pl Sp. z o.o.
                                    <br /><br />
                                    ul. Zwycięstwa 115<br />75-601 Koszalin, Polska
                                    <br /><br />
                                    NIP PL 6692518347<br />
                                    D-U-N-S Number: 426900047
                                    <br /><br />
                                    <div class="data-contact">
                                        <a href="" class="phone">+48 22 460 29 90</a>
                                        <a href="mailto:info@dogadamycie.pl" class="mail">info@dogadamycie.pl</a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="container contentbox" v-else>
                <div class="copyright">©{{ copyright_date }} Lingy.uk</div>
                <div class="labelbox"><img :src="require(`../../img/icon-footer-label.svg`)" /></div>
            </div>
        </footer>
    </div>
</template>
<script>
import Locale from "./Locale";
import {
    BDropdownItem, BNavItemDropdown, BIconArrowClockwise,
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
} from "bootstrap-vue";
import i18n from "../../../common/js/i18n";
import BrandConfig from "../BrandConfig";
import $ from "jquery";
export default {
    name: 'Footer',
    components: {
        Locale, BDropdownItem, BNavItemDropdown, BIconArrowClockwise,
        BNavbar,
        BNavbarBrand,
        BNavbarToggle,
        BCollapse,
    },
    data() {
        return {
            copyright_date: new Date().getFullYear(),
            locale: i18n.locale,
            brand_slug: BrandConfig.brandName,
            brand_metas: {
                quality_guarantee_url: null,
                faq_url: null,
                complaints: null,
                terms_of_service_url: null,
                privacy_policy_url: null,
                blog_url: null,
                free_consultation_url: null,
                csr_url: null,
                about_us_url: null,
                industries_url: null,
                services_url: null,
                languages_url: null,
                case_studies_url: null,
                contact_url: null,
                facebook_url: null,
                linkedin_url: null
            }
        }
    },
    created() {

    },
    mounted() {
        this.setLinks();
    },
    computed: {

    },
    methods: {
        setLinks() {
            let handle = setInterval(() => {
                if (window.loaded) {
                    this.brand_metas = this.$store.getters["common/brandMetas"];
                    clearInterval(handle);
                }
            }, 500);
        }
    }
}
</script>

